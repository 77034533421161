import styled from 'styled-components'
import { Button, CircularProgress } from '@material-ui/core'

export const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  margin-top: 70px;
`

export const Title = styled.h1`
  margin-bottom: 0px;
`

export const SubContainer = styled.div`
  margin-bottom: 40px;
`

export const Subtitle = styled.h2`
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 500;
`

export const Body = styled.div`
  margin-left: ${(props) => !props.noIndent && '40px'};
`

export const InfoText = styled.p`
  float: ${(props) => props.type === 'definition' && 'right'};
  width: ${(props) => props.type === 'definition' && '70%'};
  margin-top: 0px;
  margin-left: ${(props) => props.type === 'definition' && '20px'};
  margin-bottom: ${(props) => (props.type === 'important' ? '20px' : '0px')};
  font-size: 16px;
  font-weight: ${(props) => (props.type === 'important' ? '600' : '300')};
  line-height: ${(props) => (props.spaced ? '20pt' : '15pt')};
  white-space: pre-line;
`

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const RegenerateButton = styled(Button)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(',')};

    height: 36px;
    width: 200px;
    text-transform: none;
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.whiteSmoke : props.theme.colors.blue};
    & .MuiButton-label {
      color: ${(props) =>
        props.disabled
          ? props.theme.colors.mediumGray
          : props.theme.colors.white};
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.royalBlue};
    }
  }
`

export const Spinner = styled(CircularProgress)`
  && {
    color: ${(props) => props.theme.colors.blue};
  }
`

export const HelperText = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  text-align: ${(props) => props.format === 'center' && props.format};
  white-space: pre-wrap;
  font-size: ${(props) => (props.size ? props.size : '12px')};
  margin-top: ${(props) => props.margin === 'top' && '0px'};
`
