import he from 'he'
import moment from 'moment-timezone'

import { decodeQuotes, isRC } from '../../services/utils'

// Components
import {
  TableContainer,
  Header,
  StyledRow,
  CellBody,
  InstallLink,
  BuildDiv,
} from './style'
import StarIcon from '../shared/StarIcon/StarIcon'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'

const BuildsTable = ({ app, platform, builds, handleOpenInstallDialog }) => {
  return (
    <TableContainer>
      <colgroup>
        <col width="15%" />
        <col width="15%" />
        <col width="50%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
      </colgroup>
      <TableHead>
        <StyledRow>
          <Header>Build</Header>
          <Header align="left">SHA</Header>
          <Header align="left">Details</Header>
          <Header align="left">Author</Header>
          <Header align="left">Date Uploaded</Header>
          <Header align="center">Expires (Days)</Header>
        </StyledRow>
      </TableHead>
      <TableBody>
        {builds.map((build, i) => (
          <StyledRow
            id={build.sha}
            hover
            key={i}
            onClick={() => handleOpenInstallDialog(build)}
          >
            <CellBody component="th" scope="row">
              <BuildDiv>
                {isRC(build.tags) && <StarIcon margin="right" />}
                <InstallLink to={`/build/${app}/${platform}/${build.sha}`}>
                  {he.encode(build.build)}
                </InstallLink>
              </BuildDiv>
            </CellBody>
            <CellBody align="left">{he.encode(build.sha)}</CellBody>
            <CellBody align="left">
              {decodeQuotes(he.encode(build.notes))}
            </CellBody>
            <CellBody align="left">
              {decodeQuotes(he.encode(build.author))}
            </CellBody>
            <CellBody align="left">
              {moment
                .tz(build.date, moment.tz.guess())
                .format('MMM Do YYYY, h:mm a')}
            </CellBody>
            <CellBody align="center">{build.expires}</CellBody>
          </StyledRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default BuildsTable
