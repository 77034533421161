import { LoadingContainer, Title, Spinner } from './style'

const Loading = ({ display }) => {
  return (
    <LoadingContainer display={display}>
      <Title>Loading...</Title>
      <Spinner />
    </LoadingContainer>
  )
}

export default Loading
