import styled from 'styled-components'

import { TextField, MenuItem } from '@material-ui/core'

export const Dropdown = styled(TextField)`
  && {
    font-weight: 300;
    width: 200px;
    margin-right: 10px;
    z-index: 0; // Fixes issue with label floating over header
  }
`

export const MenuOption = styled(MenuItem)`
  && {
    font-weight: 300;
    height: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
`
