import { StyledButton } from './style'
import { Link } from 'react-router-dom'

const PrimaryButton = ({ kind, onClick, text }) => {
  return (
    <div>
      {kind === 'link' ? (
        <StyledButton component={Link} to="/">
          {text}
        </StyledButton>
      ) : (
        <StyledButton onClick={onClick}>{text}</StyledButton>
      )}
    </div>
  )
}

export default PrimaryButton
