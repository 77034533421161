import { Dropdown, MenuOption } from './style'

const FilterDropdown = ({
  id,
  disabled,
  type,
  defaultValue,
  changeHandler,
  filters,
}) => {
  return (
    <Dropdown
      select
      id={id}
      disabled={disabled}
      label={type}
      value={defaultValue}
      onChange={(e) => changeHandler(e)}
      margin="normal"
      variant="outlined"
    >
      {filters.map((filter, i) => {
        return (
          <MenuOption key={i} value={filter}>
            {filter}
          </MenuOption>
        )
      })}
    </Dropdown>
  )
}

export default FilterDropdown
