import styled from 'styled-components'
import { Button, SvgIcon } from '@material-ui/core'

export const InstallIcon = styled(SvgIcon)`
  margin-left: 5px;
`

export const StyledButton = styled(Button)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(',')};

    margin-top: ${(props) => props.display !== 'dialog' && '20px'};
    width: ${(props) => (props.display === 'dialog' ? '150px' : '200px')};
    text-transform: none;
    background-color: ${(props) => props.theme.colors.blue};
    & .MuiButton-label {
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.royalBlue};
    }
  }
`
