import styled from 'styled-components'
import { TextField, Button, CircularProgress } from '@material-ui/core'

export const RegisterDeviceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: 100%;
  margin-top: 70px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const Title = styled.h1`
  margin-bottom: 0px;
`
export const Subtitle = styled.h3`
  margin-top: 5px;
  font-weight: 100;
  color: ${(props) => props.theme.colors.darkGray};
`

export const BodyContainer = styled.div`
  width: 100%;
`
export const InfoText = styled.p`
  font-size: 14px;
  margin-bottom: ${(props) => props.type === 'important' && '20px'};
  font-weight: ${(props) => props.type === 'important' && '600'};
  color: ${(props) => props.type === 'important' && props.theme.colors.blue};
  line-height: 15pt;
`
export const DeviceForm = styled.form`
  display: flex;
  flex-direction: column;
`
export const InputContainer = styled.div`
  margin-bottom: 20px;
`
export const InputField = styled(TextField)`
  z-index: 0; // Fixes issue with label floating over header

  &.MuiFormControl-marginNormal {
    margin-bottom: 0px;
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.colors.blue};

    &.Mui-focused {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props) => props.theme.colors.blue};
    }
    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.darkBlue};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.colors.blue};
    }
  }
`
export const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`
export const HelperText = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  text-align: ${(props) => props.format === 'center' && props.format};
  white-space: pre-wrap;
  font-size: ${(props) => (props.size ? props.size : '12px')};
  margin-top: ${(props) => props.margin === 'top' && '0px'};
`

export const RegisterButton = styled(Button)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(',')};

    height: 36px;
    width: 100px;
    text-transform: none;
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.whiteSmoke : props.theme.colors.blue};
    & .MuiButton-label {
      color: ${(props) =>
        props.disabled
          ? props.theme.colors.mediumGray
          : props.theme.colors.white};
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.royalBlue};
    }
  }
`

export const Spinner = styled(CircularProgress)`
  && {
    color: ${(props) => props.theme.colors.blue};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const HighlightedText = styled.b`
  color: ${(props) => props.theme.colors.blue};
  font-weight: 400;
`
