export const decodeQuotes = (str) => {
  return str
    .replace(/&#x22;/g, '"')
    .replace(/&#x26;#x27;/g, "'")
    .replace(/&#x27;/g, "'")
    .replace(/&#x26;#x2F;/g, '/')
    .replace(/&#x9;/g, '\t')
    .replace(/&#x60;/g, '`')
}

export const isRC = (tags) => {
  if (tags !== undefined) {
    return tags.includes('rc-debug') || tags.includes('rc-release')
  } else {
    return false
  }
}
