import styled from 'styled-components'

export const InstallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 70px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const InstallInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.theme.sizing.install.container};
  height: 100%;
`

export const HeadingContainer = styled.div`
  margin: 0px 20px 0px 20px;
  width: ${(props) => props.theme.sizing.install.item};
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  margin-top: 0px;
  margin-bottom: 0px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${(props) => props.theme.sizing.install.item};
  margin: 20px;
`
