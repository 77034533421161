import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`

export const Text = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.darkGray};
`
