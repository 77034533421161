import styled from 'styled-components'
import { Button, ButtonGroup } from '@material-ui/core'

export const Group = styled(ButtonGroup)`
  width: ${(props) => props.type === 'appcard' && '90%'};
  margin-left: ${(props) => props.margin === 'left' && '20px'};
`
export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};

    width: ${(props) => props.type === 'appcard' && '100%'};
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.whiteSmoke : 'transparent'};
    & .MuiButton-label {
      color: ${(props) =>
        props.disabled
          ? props.theme.colors.mediumGray
          : props.theme.colors.blue};
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.blue};
      color: ${(props) => props.theme.colors.white};
      & .MuiButton-label {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`
