import { NotAuthorizedContainer, Title, Text } from './style'
import PrimaryButton from '../shared/PrimaryButton/PrimaryButton'

const NotAuthorized = ({ text }) => {
  return (
    <NotAuthorizedContainer marginTop={!text && '70px'}>
      <Title>Not Authorized</Title>
      <Text>
        Only those logged in and authorized can see this page's contents.
      </Text>
      <PrimaryButton text="Return home" kind="link" />
    </NotAuthorizedContainer>
  )
}

export default NotAuthorized
