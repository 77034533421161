// Components
import {
  CardContainer,
  ImageContainer,
  Image,
  Content,
  Title,
  Description,
} from './style.js'
import PlatformGroup from '../shared/PlatformGroup/PlatformGroup'
import { withEnv } from '@praxis/component-runtime-env'

export const AppCard = ({ app, onChangePlatform, env, type }) => {
  return (
    <CardContainer>
      <ImageContainer>
        <Image src={app.icon_url ? app.icon_url : env.defaultAppIcon} />
      </ImageContainer>
      <Content>
        <Title>{app.name}</Title>
        <Description>{app.description}</Description>
        <PlatformGroup
          app={app.root}
          onChangePlatform={onChangePlatform}
          type={type}
        />
      </Content>
    </CardContainer>
  )
}

export default withEnv()(AppCard)
