import React from 'react'
import { connect } from 'react-redux'
import { withAuth } from '@praxis/component-auth'
import moment from 'moment-timezone'

// Actions
import { registerDevice, clearForm } from '../../actions/deviceAction'

// Reducers/Selectors
import {
  postDeviceMessage,
  isPostDevicePending,
  isPostDeviceSuccessful,
  postDeviceError,
} from '../../reducers/deviceReducer'

// Components
import {
  RegisterDeviceContainer,
  HeaderContainer,
  Title,
  Subtitle,
  BodyContainer,
  InfoText,
  DeviceForm,
  InputContainer,
  InputField,
  RegisterButton,
  HelperTextContainer,
  HelperText,
  ButtonContainer,
  Spinner,
  HighlightedText,
} from './style'
import { Divider } from '@material-ui/core'

export class RegisterDevice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      nameSuffix: `(${moment().format('M/YYYY')}-${
        props.auth.session.userInfo.lanId
      })`,
      nameLimit: 50,
      udid: '',
      nameValid: false,
      udidValid: false,
      nameError: '',
      udidError: '',
      formValid: false,
    }
  }

  componentWillUnmount() {
    this.props.clearForm()
  }

  onChange = (event) => {
    var name = event.target.id
    var value = event.target.value

    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  validateField = (fieldName, value) => {
    var { nameError, udidError, nameValid, udidValid, nameSuffix, nameLimit } =
      this.state
    switch (fieldName) {
      case 'name':
        var deviceName = `${value} ${nameSuffix}`
        nameValid =
          !/[!@#$%^&*=[\]{};\\,<>/?]/g.test(value) &&
          deviceName.length <= nameLimit
        nameError =
          (deviceName.length > nameLimit &&
            `Device name needs to be ${
              nameLimit - nameSuffix.length - 1
            } characters or less`) ||
          (!nameValid && 'Invalid characters')
        break
      case 'udid':
        udidValid =
          /^[a-zA-Z0-9]{40}$/g.test(value) ||
          /^[0-9]{8}-[a-zA-Z0-9]{16}$/g.test(value) ||
          value.length === 0
        udidError = (!udidValid || value === ' ') && 'Invalid format'
        break
      default:
        break
    }

    this.setState(
      {
        nameValid: nameValid,
        udidValid: udidValid,
        nameError: nameError,
        udidError: udidError,
      },
      this.validateForm,
    )
  }

  validateForm = () => {
    this.setState({
      formValid:
        this.state.nameValid &&
        this.state.udidValid &&
        this.state.name !== ' ' &&
        this.state.udid !== ' ',
    })
  }

  handleRegister = (name, udid) => {
    this.props.registerDevice(`${name} ${this.state.nameSuffix}`, udid)
  }

  render() {
    const { deviceMessage, isDevicePending, isDeviceSuccessful, deviceErrors } =
      this.props
    return (
      <RegisterDeviceContainer>
        <HeaderContainer>
          <Title>Register Device</Title>
          <Subtitle>
            To test locally on a personal device, please register it using this
            form.
          </Subtitle>
          <Divider variant="fullWidth" />
        </HeaderContainer>
        <BodyContainer>
          <InfoText>
            This form registers the given device name and UDID to the Apple
            Developer Portal and regenerates all{' '}
            <HighlightedText>Flagship</HighlightedText> provisioning profiles.
            <br />
            <br />
            If you want to register a device to install non-Flagship iOS builds,
            you will instead need to create the appropriate provisioning
            profiles and register the device directly to the Apple Developer
            Portal. For more information, post a message in the{' '}
            <HighlightedText>#bullflight</HighlightedText> channel.
          </InfoText>
          <InfoText type="important">
            Note: Only builds created after registering will work on your
            device. BullFlight iOS builds on 2 hour intervals if there are new
            registered devices.
          </InfoText>
          <DeviceForm>
            <InputContainer margin="bottom">
              <InputField
                id="name"
                label="Device Name"
                value={this.state.name}
                error={!!this.state.nameError}
                onChange={this.onChange}
                placeholder="Enter device name"
                helperText={!!this.state.nameError && this.state.nameError}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <HelperTextContainer>
                <HelperText>
                  i.e. "Lisa Robinson's iPhone 12 Pro Max"
                </HelperText>
                <HelperText margin="top">
                  Allowed special characters: ', :, ., +, (), -, _
                </HelperText>
              </HelperTextContainer>
            </InputContainer>
            <InputContainer>
              <InputField
                id="udid"
                label="Device UDID"
                value={this.state.udid}
                error={!!this.state.udidError}
                onChange={this.onChange}
                placeholder="Enter device UDID"
                helperText={this.state.udidError}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <HelperTextContainer>
                <HelperText>
                  Don't know how to find it?
                  https://pages.git.target.com/mobile-apps/guide/docs/flagship-ios/?_highlight=udid#registering-your-test-device
                </HelperText>
              </HelperTextContainer>
            </InputContainer>
          </DeviceForm>
          <ButtonContainer>
            <RegisterButton
              disabled={
                !this.state.formValid ||
                this.state.name.length === 0 ||
                this.state.udid.length === 0 ||
                isDevicePending
              }
              onClick={() =>
                this.handleRegister(this.state.name, this.state.udid)
              }
            >
              {this.state.formValid && isDevicePending ? (
                <Spinner size={24} />
              ) : (
                'Register'
              )}
            </RegisterButton>
            {!isDevicePending && isDeviceSuccessful && deviceMessage ? (
              <HelperText color="success" format="center" size="14px">
                {deviceMessage}
              </HelperText>
            ) : !isDevicePending && deviceErrors ? (
              <HelperText color="success" format="center" size="14px">
                {deviceErrors.message}
              </HelperText>
            ) : (
              isDevicePending && ''
            )}
          </ButtonContainer>
        </BodyContainer>
      </RegisterDeviceContainer>
    )
  }
}

export default connect(
  (state) => ({
    deviceMessage: postDeviceMessage(state),
    isDevicePending: isPostDevicePending(state),
    isDeviceSuccessful: isPostDeviceSuccessful(state),
    deviceErrors: postDeviceError(state),
  }),
  {
    registerDevice: registerDevice,
    clearForm: clearForm,
  },
)(withAuth()(RegisterDevice))
