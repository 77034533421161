import styled from 'styled-components'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Tooltip } from '@material-ui/core'

export const Icon = styled(SvgIcon)`
  color: ${(props) => props.theme.colors.yellow};
  margin-right: ${(props) => props.margin === 'right' && '5px'};
`

export const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))`
  & .tooltip {
    font-size: 13px;
    font-weight: 400;
    height: 100%;
    width: 85%;
    background-color: ${(props) => props.theme.colors.lighterGray};
    color: ${(props) => props.theme.colors.darkGray};
    border: 1px solid ${(props) => props.theme.colors.lightGray};
  }
`
