import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const StyledButton = styled(Button)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(',')};

    width: 200px;
    text-transform: none;
    background-color: ${(props) => props.theme.colors.blue};
    & .MuiButton-label {
      color: ${(props) => props.theme.colors.white};
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.royalBlue};
    }
  }
`
