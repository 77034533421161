import * as types from './actionTypes'
import axios from 'axios'
import appConfig from '../config/appConfig'

export function regenerateProfiles(name, udid) {
  return (dispatch) => {
    dispatch(postProfilesPending(true))
    return appConfig().then((config) => {
      return axios({
        method: 'post',
        url: `${config.api}/profiles?key=${config.key}`,
      })
        .then((response) => {
          dispatch(postProfilesData(response.data))
          dispatch(postProfilesSuccess(true))
          dispatch(postProfilesPending(false))
          dispatch(postProfilesError({ status: '', message: '' }))
        })
        .catch((err) => {
          dispatch(postProfilesSuccess(false))
          dispatch(postProfilesPending(false))
          dispatch(
            postProfilesError({
              status:
                err.response && err.response.status ? err.response.status : 500,
              message:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Internal Server Error',
            }),
          )
        })
    })
  }
}

function postProfilesPending(isPending) {
  return {
    type: types.POST_PROFILES_PENDING,
    isPending,
  }
}

function postProfilesData(data) {
  return {
    type: types.POST_PROFILES_DATA,
    data,
  }
}

function postProfilesSuccess(isSuccessful) {
  return {
    type: types.POST_PROFILES_SUCCESS,
    isSuccessful,
  }
}

function postProfilesError(error) {
  return {
    type: types.POST_PROFILES_ERROR,
    error,
  }
}

export function clearPage() {
  return (dispatch) => {
    dispatch(postProfilesData({ message: '' }))
    dispatch(postProfilesSuccess(false))
    dispatch(postProfilesPending(false))
    dispatch(postProfilesError({ status: '', message: '' }))
  }
}
