import styled from 'styled-components'

import { Button } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

export const Bar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.lighterGray};
  height: 50px;
  z-index: 1;
`
export const HeaderButton = styled(Button)`
  && {
    margin-left: ${(props) => props.margin};
    right: ${(props) => (props.button === 'logout' ? '10px' : '0px')};
    text-transform: none;

    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};

    &:hover {
      background-color: transparent;
    }

    & .MuiButton-label {
      color: ${(props) => props.theme.colors.darkGray};
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const HomeIcon = styled(SvgIcon)`
  &:hover {
    color: ${(props) => props.theme.colors.lightGray};
    background-color: transparent;
  }
`

export const Text = styled.p`
  font-weight: ${(props) => props.important && '400'};
  font-size: 17px;
  color: ${(props) =>
    props.important ? props.theme.colors.blue : props.theme.colors.darkGray};
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  &:hover {
    color: ${(props) =>
      props.important
        ? props.theme.colors.darkBlue
        : props.theme.colors.lightGray};
    background-color: transparent;
  }
`
export const RightButtons = styled.div`
  margin-left: auto;
`
