import { NotFoundContainer, Title, Text } from './style'
import PrimaryButton from '../shared/PrimaryButton/PrimaryButton'

const NotFound = ({ text }) => {
  return (
    <NotFoundContainer marginTop={!text && '70px'}>
      <Title>Not Found</Title>
      <Text>{text ? text : "The page that was requested doesn't exist."}</Text>
      <PrimaryButton text="Return home" kind="link" />
    </NotFoundContainer>
  )
}

export default NotFound
