import { combineReducers } from 'redux'
import apps from './appReducer'
import builds from './buildReducer'
import auth from './authReducer'
import device from './deviceReducer'
import profiles from './adminReducer'

const rootReducer = combineReducers({
  apps,
  builds,
  auth,
  device,
  profiles,
})

export default rootReducer
