// Components
import {
  DialogInstall,
  DialogContainer,
  HeadingContainer,
  TitleContainer,
  Title,
  ContentContainer,
  InfoText,
  TitleDiv,
  ButtonDiv,
  ClearFloat,
} from './style'
import {
  BuildContent,
  InstallContent,
  Button,
  Footer,
  Loading,
} from '../shared/Install'
import { Divider } from '@material-ui/core'
import { withEnv } from '@praxis/component-runtime-env'
import { isEmpty } from 'lodash'
import { isRC } from '../../services/utils'
import StarIcon from '../shared/StarIcon/StarIcon'

export const InstallDialog = ({
  build,
  open,
  close,
  pending,
  app,
  platform,
  env,
}) => {
  return (
    <DialogInstall
      open={open}
      onClose={close}
      size={!pending && isEmpty(build) ? '500px' : undefined}
    >
      {pending ? (
        <Loading />
      ) : isEmpty(build) ? (
        <DialogContainer>
          <HeadingContainer>
            <TitleContainer>
              <Title>Install</Title>
            </TitleContainer>
            <Divider variant="fullWidth" />
          </HeadingContainer>
          <ContentContainer>
            <InfoText>No build found.</InfoText>
          </ContentContainer>
        </DialogContainer>
      ) : (
        <DialogContainer>
          <HeadingContainer>
            <TitleContainer>
              <TitleDiv>
                <Title>Install</Title>
                {open && isRC(build.tags) && <StarIcon />}
              </TitleDiv>
              <ButtonDiv>
                <Button display="dialog" url={build.url} />
              </ButtonDiv>
            </TitleContainer>
            <ClearFloat />
            <Divider variant="fullWidth" />
          </HeadingContainer>
          <ContentContainer>
            <BuildContent platform={platform} build={build} />
            <InstallContent buildURL={build.url} qrCodeSize={170} />
          </ContentContainer>
          <Footer />
        </DialogContainer>
      )}
    </DialogInstall>
  )
}

export default withEnv()(InstallDialog)
