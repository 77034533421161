import React from 'react'
import { connect } from 'react-redux'

// Actions
import { regenerateProfiles, clearPage } from '../../actions/adminAction'

// Reducers/Selectors
import {
  postProfilesMessage,
  isPostProfilesPending,
  isPostProfilesSuccessful,
  postProfilesError,
} from '../../reducers/adminReducer'

// Components
import {
  AdminContainer,
  Title,
  SubContainer,
  Subtitle,
  Body,
  InfoText,
  ButtonDiv,
  RegenerateButton,
  Spinner,
  HelperText,
} from './style'
import { Divider } from '@material-ui/core'

export class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    this.props.clearPage()
  }

  render() {
    const {
      postProfilesMessage,
      isPostProfilesPending,
      isPostProfilesSuccessful,
      postProfilesError,
    } = this.props

    return (
      <AdminContainer>
        <SubContainer>
          <Title>Admin</Title>
          <br />
          <Divider variant="fullWidth" />
        </SubContainer>
        <SubContainer>
          <Subtitle>Regenerate Profiles</Subtitle>
          <Body noIndent>
            <InfoText spaced>
              To regenerate all provisioning profiles on the Apple Developer
              Portal, click the button below:
            </InfoText>
            <br />
            <ButtonDiv>
              <RegenerateButton
                disabled={isPostProfilesPending}
                onClick={this.props.regenerateProfiles}
              >
                {isPostProfilesPending ? (
                  <Spinner size={24} />
                ) : (
                  'Regenerate ✨'
                )}
              </RegenerateButton>

              {!isPostProfilesPending &&
              isPostProfilesSuccessful &&
              postProfilesMessage ? (
                <HelperText color="success" format="center" size="14px">
                  {postProfilesMessage}
                </HelperText>
              ) : !isPostProfilesPending && postProfilesError ? (
                <HelperText color="success" format="center" size="14px">
                  {postProfilesError.message}
                </HelperText>
              ) : (
                isPostProfilesPending && ''
              )}
            </ButtonDiv>
          </Body>
        </SubContainer>
      </AdminContainer>
    )
  }
}

export default connect(
  (state) => ({
    postProfilesMessage: postProfilesMessage(state),
    isPostProfilesPending: isPostProfilesPending(state),
    isPostProfilesSuccessful: isPostProfilesSuccessful(state),
    postProfilesError: postProfilesError(state),
  }),
  {
    regenerateProfiles: regenerateProfiles,
    clearPage: clearPage,
  },
)(Admin)
