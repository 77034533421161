import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

// Actions
import { getBuilds, getBuild, clearBuilds } from '../../actions/buildAction'
import {
  getTags,
  getVersions,
  getAppName,
  getBuildsSuccessful,
  getBuildsPending,
  getBuildsErrors,
  selectedBuild,
  getBuildPending,
  getBuildErrors,
} from '../../reducers/buildReducer'

// Components
import {
  BuildsContainer,
  HeadingContainer,
  BuildsTableContainer,
  TitleContainer,
  Title,
  FiltersContainer,
  FiltersTitleContainer,
  FiltersTitle,
  FilterIcon,
  FiltersDropdownContainer,
  ClearFilterContainer,
  ClearFiltersButton,
  Container,
} from './style'
import { TablePagination } from '@material-ui/core'
import BuildsTable from '../../components/BuildsTable/BuildsTable'
import InstallDialog from '../../components/InstallDialog/InstallDialog'
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown'
import PlatformGroup from '../../components/shared/PlatformGroup/PlatformGroup'
import Loading from '../../components/shared/Install/Loading/Loading'
import NotFound from '../../components/NotFound/NotFound'

const initialState = {
  selectedVersion: '',
  selectedTag: '',
  openInstallDialog: false,
  page: 0,
  rowsPerPage: 50,
  app: '',
  platform: '',
  initialRender: false,
  filteredBuilds: [],
  unfilteredBuilds: [],
  buildsCount: 0,
}

export class Builds extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = initialState
  }

  async componentDidMount() {
    this._isMounted = true
    var { app, platform } = this.props
    const query = queryString.parse(this.props.location.search)

    var res = await this.props.getBuilds(app, platform)
    if (res !== undefined) {
      var builds = res.data.builds
      const tagValid =
        !!query.tag && !/[!@#$%^&*_=[\]{};\\,<>?]/g.test(query.tag)
      const versionValid =
        !!query.version && !/[!@#$%^&*_\-=[\]{};\\,<>/?]/g.test(query.version)

      var state = {
        ...initialState,
        app: app,
        platform: platform,
      }

      var filteredState = {}
      if (tagValid && versionValid) {
        filteredState = this.getDefaultFilterState(
          app,
          platform,
          builds,
          'both',
          query,
        )
      } else if (tagValid) {
        filteredState = this.getDefaultFilterState(
          app,
          platform,
          builds,
          'tag',
          query.tag,
        )
      } else if (versionValid) {
        filteredState = this.getDefaultFilterState(
          app,
          platform,
          builds,
          'version',
          query.version,
        )
      } else {
        // If specified tag or version not valid, display default builds
        // For Flagship = default tagged builds, non-Flagship apps = unfiltered builds
        filteredState = this.getDefaultFilterState(app, platform, builds, 'tag')
      }

      if (this._isMounted) {
        this.setState({
          ...state,
          ...filteredState,
        })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.clearBuilds()
  }

  async componentDidUpdate(prevProps, prevState) {
    var filtered = []
    if (
      (this.state.selectedTag !== '' &&
        prevState.selectedTag !== this.state.selectedTag) ||
      (this.state.selectedVersion !== '' &&
        prevState.selectedVersion !== this.state.selectedVersion)
    ) {
      var params
      if (
        (prevState.selectedTag !== this.state.selectedTag &&
          prevState.selectedVersion !== this.state.selectedVersion) ||
        (prevState.selectedTag !== this.state.selectedTag &&
          this.state.selectedVersion !== '') ||
        (prevState.selectedVersion !== this.state.selectedVersion &&
          this.state.selectedTag !== '')
      ) {
        // Both filters selected
        filtered = this.state.unfilteredBuilds.filter((build) => {
          return (
            build.tags.includes(this.state.selectedTag) &&
            build.appVersion.includes(this.state.selectedVersion) === true
          )
        })
        params = `tag=${this.state.selectedTag}&version=${this.state.selectedVersion}`
      } else if (prevState.selectedTag !== this.state.selectedTag) {
        // Only tag selected
        filtered = this.state.unfilteredBuilds.filter((build) => {
          return (
            build.tags && build.tags.includes(this.state.selectedTag) === true
          )
        })
        params = `tag=${this.state.selectedTag}`
      } else if (prevState.selectedVersion !== this.state.selectedVersion) {
        // Only version selected
        filtered = this.state.unfilteredBuilds.filter((build) => {
          return build.appVersion.includes(this.state.selectedVersion) === true
        })
        params = `version=${this.state.selectedVersion}`
      }

      this.setState(
        {
          ...this.state,
          filteredBuilds: filtered,
          buildsCount: filtered.length,
        },
        () => {
          this.props.history(`${this.props.location.pathname}?${params}`)
        },
      )
    }

    if (prevState.platform !== this.state.platform) {
      // This executes when page initially renders too
      this.props.history(`/build/${this.state.app}/${this.state.platform}`)

      if (prevState.platform !== '') {
        // Not the first initial render
        var res = await this.props.getBuilds(
          this.state.app,
          this.state.platform,
        )

        if (res !== undefined) {
          this.setState({
            ...this.state,
            ...this.getDefaultFilterState(
              this.state.app,
              this.state.platform,
              res.data.builds,
              'tag',
            ),
          })
        }
      }
    }
  }

  getDefaultFilterState = (app, platform, builds, filterType, filter) => {
    // This method is for setting the state properties related to displaying filters when page initially renders
    var state = {}
    var filtered = []
    if (filterType === 'both') {
      filtered = builds.filter((build) => {
        return (
          build.tags.includes(filter.tag) &&
          build.appVersion.includes(filter.version) === true
        )
      })
      state = {
        selectedTag: filtered.length > 0 ? filter.tag : '',
        selectedVersion: filtered.length > 0 ? filter.version : '',
      }
    } else if (filterType === 'tag') {
      var tag =
        app === 'flagship' && platform === 'ios' && !filter
          ? 'main'
          : app === 'flagship' && platform === 'android' && !filter
            ? 'main-debug'
            : filter
              ? filter
              : ''

      if (tag !== '') {
        // If no builds exist with the tag, display the unfilteredBuilds
        filtered = builds.filter((build) => {
          return build.tags && build.tags.includes(tag) === true
        })
      }
      state = {
        selectedTag: filtered.length > 0 ? tag : '',
      }
    } else if (filterType === 'version') {
      filtered = builds.filter((build) => {
        return build.appVersion.includes(filter) === true
      })
      state = {
        selectedVersion: filtered.length > 0 ? filter : '',
      }
    }
    return {
      ...state,
      buildsCount: filtered.length > 0 ? filtered.length : builds.length,
      filteredBuilds: filtered,
      unfilteredBuilds: builds,
    }
  }

  resetState = () => {
    this.setState(
      {
        ...initialState,
        app: this.state.app,
        platform: this.state.platform,
        filteredBuilds: this.state.filteredBuilds,
        unfilteredBuilds: this.state.unfilteredBuilds,
        buildsCount: this.state.unfilteredBuilds.length,
      },
      () => this.props.history.push(`${this.props.location.pathname}`),
    )
  }

  handleChangeSelector = (filter) => (event) => {
    this.setState({
      ...this.state,
      page: 0,
      ...(filter === 'version'
        ? { selectedVersion: event.target.value }
        : { selectedTag: event.target.value }),
    })
  }

  handleOpenInstallDialog = (build) => {
    this.setState({ openInstallDialog: true })
    this.props.getBuild(this.props.app, this.props.platform, build.sha)
  }

  handleCloseInstallDialog = () => {
    this.setState({ openInstallDialog: false })
  }

  handlePageChange = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleRowsPerPageChange = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  handleChangePlatform = (app, platform) => {
    this.setState({
      ...initialState,
      app: app,
      platform: platform,
    })
  }

  render() {
    const {
      tags,
      versions,
      appName,
      isBuildsSuccessful,
      isBuildsPending,
      buildsErrors,
      selectedBuild,
      isBuildPending,
      buildErrors,
    } = this.props

    return (
      <BuildsContainer>
        {buildsErrors && buildsErrors.status === 404 ? (
          <NotFound text={buildsErrors.message} />
        ) : buildsErrors && buildsErrors.status === 422 ? (
          <NotFound text={'Invalid query parameters.'} />
        ) : buildsErrors && buildsErrors.status === 500 ? (
          <NotFound text={'The server is currently down 😓'} />
        ) : (
          <Container>
            <HeadingContainer>
              <TitleContainer>
                <Title>{appName}</Title>
                <PlatformGroup
                  app={this.state.app}
                  platform={this.state.platform}
                  margin="left"
                  size="small"
                  onChangePlatform={this.handleChangePlatform}
                />
              </TitleContainer>
              <FiltersContainer>
                <FiltersTitleContainer>
                  <FilterIcon>
                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                  </FilterIcon>
                  <FiltersTitle>Filter by</FiltersTitle>
                </FiltersTitleContainer>
                <FiltersDropdownContainer>
                  {versions && versions.length > 0 && (
                    <FilterDropdown
                      id="version-selector"
                      type="Version"
                      defaultValue={this.state.selectedVersion}
                      changeHandler={this.handleChangeSelector('version')}
                      filters={versions}
                    />
                  )}
                  {tags && tags.length > 0 && (
                    <FilterDropdown
                      id="tag-selector"
                      type="Tag"
                      defaultValue={this.state.selectedTag}
                      changeHandler={this.handleChangeSelector('tag')}
                      filters={tags}
                    />
                  )}
                </FiltersDropdownContainer>
                {(this.state.selectedTag !== '' ||
                  this.state.selectedVersion !== '') && (
                  <ClearFilterContainer>
                    <ClearFiltersButton
                      disableRipple
                      onClick={() => this.resetState()}
                    >
                      Clear Filters
                    </ClearFiltersButton>
                  </ClearFilterContainer>
                )}
              </FiltersContainer>
            </HeadingContainer>
            {!isBuildsPending && isBuildsSuccessful ? (
              <BuildsTableContainer>
                <BuildsTable
                  app={this.state.app}
                  platform={this.state.platform}
                  handleOpenInstallDialog={this.handleOpenInstallDialog}
                  builds={(this.state.selectedTag !== '' ||
                  this.state.selectedVersion !== ''
                    ? this.state.filteredBuilds
                    : this.state.unfilteredBuilds
                  ).slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage,
                  )}
                />
                <TablePagination
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  count={this.state.buildsCount}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handlePageChange}
                  onRowsPerPageChange={this.handleRowsPerPageChange}
                />
                <InstallDialog
                  open={this.state.openInstallDialog}
                  pending={isBuildPending}
                  close={this.handleCloseInstallDialog}
                  build={buildErrors.status === 404 ? {} : selectedBuild}
                  app={this.state.app}
                  platform={this.state.platform}
                />
              </BuildsTableContainer>
            ) : (
              <Loading />
            )}
          </Container>
        )}
      </BuildsContainer>
    )
  }
}

function withUseNavigate(Component) {
  return function WrappedComponent(props) {
    const history = useNavigate()
    return <Component {...props} history={history} />
  }
}

function withUseLocation(Component) {
  return function WrappedComponent(props) {
    const location = useLocation()
    return <Component {...props} location={location} />
  }
}

export default connect(
  (state) => ({
    tags: getTags(state),
    versions: getVersions(state),
    appName: getAppName(state),
    isBuildsSuccessful: getBuildsSuccessful(state),
    isBuildsPending: getBuildsPending(state),
    buildsErrors: getBuildsErrors(state),
    selectedBuild: selectedBuild(state),
    isBuildPending: getBuildPending(state),
    buildErrors: getBuildErrors(state),
  }),
  {
    getBuilds: getBuilds,
    getBuild: getBuild,
    clearBuilds: clearBuilds,
  },
)(withUseLocation(withUseNavigate(Builds)))
