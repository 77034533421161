import * as types from './actionTypes'
import axios from 'axios'
import appConfig from '../config/appConfig'

// A thunk action creator
export function getApps() {
  return (dispatch) => {
    dispatch(getAppsPending(true))
    return appConfig().then((config) => {
      return axios({
        method: 'get',
        url: `${config.api}/apps?key=${config.key}`,
      })
        .then((response) => {
          dispatch(getAppsData(response.data))
          dispatch(getAppsSuccessful(true))
          dispatch(getAppsPending(false))
        })
        .catch((err) => {
          dispatch(
            getAppsErrors(
              err.response && err.response.status ? err.response.status : 500,
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Internal Server Error',
            ),
          )
          dispatch(getAppsPending(false))
        })
    })
  }
}

function getAppsPending(isPending) {
  return {
    type: types.GET_APPS_PENDING,
    isPending,
  }
}

function getAppsData(apps) {
  return {
    type: types.GET_APPS_DATA,
    apps,
  }
}

function getAppsSuccessful(isSuccessful) {
  return {
    type: types.GET_APPS_SUCCESS,
    isSuccessful,
  }
}

function getAppsErrors(status, message) {
  return {
    type: types.GET_APPS_ERROR,
    status,
    message,
  }
}
