import styled from 'styled-components'

import Card from '@material-ui/core/Card'

export const CardContainer = styled(Card)`
  && {
    width: 300px;
    height: 430px;
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Image = styled.img`
  width: 250px;
  height: 250px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 300;
`

export const Description = styled.p`
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
`
