import { getEnvConfig } from '@praxis/component-runtime-env'
import { merge } from 'lodash'

import commonConfig from './commonConfig'

const config = async () => {
  const envConfig = await getEnvConfig('/config.json')
  return merge(commonConfig, envConfig)
}

export default config
