import {
  DocumentationContainer,
  SubContainer,
  Title,
  Subtitle,
  HeadingTitle,
  StepTitle,
  InfoText,
  StyledLink,
  ExternalLink,
  HighlightedText,
  CodeSnippet,
  Definition,
  ClearFloat,
  Note,
  Body,
} from './style'
import { Divider } from '@material-ui/core'

const Documentation = () => {
  const api =
    'https://api-internal.target.com/mobile_test_builds/v1/{app}/{platform}/{sha}'
  return (
    <DocumentationContainer>
      <SubContainer>
        <Title>Documentation</Title>
        <Divider variant="fullWidth" />
      </SubContainer>
      <SubContainer>
        <Subtitle>Apps</Subtitle>
        <HeadingTitle>Onboarding your app to BullFlight</HeadingTitle>
        <Body noIndent>
          <InfoText spaced>
            There currently is no API to add a new app to BullFlight. If you
            would like to add your app, simply post a message in the{' '}
            <HighlightedText noFloat weight="less">
              #bullflight
            </HighlightedText>{' '}
            channel with:
          </InfoText>
          <br />
          <CodeSnippet>
            <InfoText spaced>
              - The app name
              <br />
              - A description of the app
              <br />
              - If the app is on iOS, Android, or both
              <br />
              - An image for the app icon. This should be the same asset (size)
              you supply to The App Store/Play Store
              <br />
            </InfoText>
          </CodeSnippet>
        </Body>
      </SubContainer>
      <SubContainer>
        <Subtitle>Builds</Subtitle>
        <HeadingTitle>Installing builds on your device</HeadingTitle>
        <StepTitle>1. Select Your App</StepTitle>
        <Body>
          <InfoText>
            Select your app/platform from the{' '}
            <StyledLink to="/">home page</StyledLink>.
          </InfoText>
        </Body>
        <StepTitle>2. Select Your Build</StepTitle>
        <Body>
          <InfoText>
            Each app/platform page contains a list of builds posted for that
            app. The version and tag filters allow you to filter different types
            of builds.
            <br />
            <br />
            Each app or team can send and manage their own tags, but in general
            you can expect to see:
          </InfoText>
          <br />
          <br />
          <CodeSnippet>
            <Definition>
              <HighlightedText>Versions</HighlightedText>:
              <InfoText type="definition">
                Builds filtered by the version name. Often these are numbered
                like "9.33.0" and represent an eventual release to the App/Play
                stores.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>pr</HighlightedText>:
              <InfoText type="definition">
                Builds that were built from a submitted Pull Request on Github.
                These are not in the main branch and are not going to show up in
                a production build until these are merged.
              </InfoText>
            </Definition>
            <ClearFloat />
          </CodeSnippet>
          <br />
          <br />
          <InfoText>
            For Flagship iOS and Android builds, the default builds returned are
            configured to 'main' and 'main-debug' tagged builds, respectively.
            For iOS, the 'main' tag refers to 'mainline' builds generated from
            the 'develop' branch. For Android, 'main-debug' refers to 'main'
            branch generated builds with the debug flag turned on.
          </InfoText>
        </Body>
        <StepTitle>3. Install the Build</StepTitle>
        <Body>
          <InfoText>
            Once you select a build from the build list, by either clicking on
            the row or on the name of the build, you are presented with the
            install dialog or install page.
            <br />
            To install, simply tap on the "Install" button from your mobile
            device.
            <br />
            <br />
            On the install dialog and page, there's a QR code, which if scanned
            with your mobile device, will open up the install page for the
            build. This makes it easier to open up the install page on a device
            from the desktop.
            <br />
            <br />
            If you are not on a mobile device:
          </InfoText>
          <br />
          <br />
          <CodeSnippet>
            <HighlightedText>Android</HighlightedText>:
            <InfoText type="definition">The .apk will be downloaded</InfoText>
            <ClearFloat />
            <br />
            <br />
            <HighlightedText>iOS</HighlightedText>:
            <InfoText type="definition">
              You will be presented with an unpresentable link. Downloading the
              .ipa from a desktop is a potential enhancement.
            </InfoText>
            <ClearFloat />
          </CodeSnippet>
        </Body>
        <br />
        <br />
        <Note>
          <StepTitle type="important" noLeftMargin>
            Note: Installing on iOS Devices
          </StepTitle>
          <InfoText>
            Installing iOS apps "over the air" requires the app to have a
            provisioning profile that tells the device that the app is allowed
            to be installed.
            <br />
            <br />
            If it is your first time installing a build from BullFlight onto
            your phone, chances are you'll need to{' '}
            <StyledLink to="/register-device">register your device</StyledLink>.
            Afterwards, you will be able to install all builds generated after
            your device has been registered.
            <br />
            <br />
            Beware, this form only registers a device with the provisioning
            profiles for the Flagship iOS Target app. In other words, if your
            app is a non-Flagship iOS app, you will need to set up the
            appropriate provisoning profile(s) specific to your app and register
            devices directly through the Apple Developer Portal. For more
            information, post a message in the{' '}
            <HighlightedText noFloat weight="less">
              #bullflight
            </HighlightedText>{' '}
            channel.
          </InfoText>
        </Note>
        <br />
        <br />
        <HeadingTitle>Uploading builds to BullFlight</HeadingTitle>
        <InfoText>
          To post a new build, make an HTTP POST call to the following URL with
          the appropriate parameters:
        </InfoText>
        <StepTitle>URL</StepTitle>
        <Body>
          <CodeSnippet>
            <InfoText>{api}</InfoText>
          </CodeSnippet>
        </Body>
        <br />
        <StepTitle>Path Parameters</StepTitle>
        <Body>
          <CodeSnippet>
            <Definition>
              <HighlightedText>app</HighlightedText>:
              <InfoText type="definition">The app you are posting to</InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>platform</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  ios
                </HighlightedText>{' '}
                or{' '}
                <HighlightedText noFloat weight="less">
                  android
                </HighlightedText>
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>sha</HighlightedText>:
              <InfoText type="definition">
                A 7-10 digit SHA prefix of the commit used to build the version
                being posted
                <br />
                <HighlightedText noFloat weight="less">
                  Note: The SHA is simply used as an identifier. In special
                  circumstances, you could put any unique value here.
                </HighlightedText>
              </InfoText>
            </Definition>
            <ClearFloat />
          </CodeSnippet>
        </Body>
        <br />
        <StepTitle>Query Parameters</StepTitle>
        <Body>
          <CodeSnippet>
            <Definition>
              <HighlightedText>key</HighlightedText>:
              <InfoText type="definition">
                A production API key generated from the{' '}
                <ExternalLink href="https://developer.target.com/">
                  Developer Portal
                </ExternalLink>
              </InfoText>
            </Definition>
            <ClearFloat />
          </CodeSnippet>
        </Body>
        <br />
        <StepTitle>Form Parameters</StepTitle>
        <Body>
          <CodeSnippet>
            <Definition>
              <HighlightedText>apk</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                A file reference to the apk if uploading an Android build
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>ipa</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                A file reference to the ipa if uploading an iOS build
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>tags</HighlightedText>:
              <InfoText type="definition">
                A comma-separated list of tags to apply. Builds can be filtered
                on tags.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>expire_in</HighlightedText>:
              <InfoText type="definition">
                The number of days the build will be active until it is removed
                from the system. Use this field to promote important builds and
                remove the clutter of many ephemeral builds. The current max
                value is 730 days (2 years).
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>notes</HighlightedText>:
              <InfoText type="definition">
                A short (sentence or two) description of the build
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>author</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                The author of the last commit of the build.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>changelog</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                A file reference to a long-format changelog. This supports
                formatted newlines and is displayed on build details pages.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>mapping</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                For (Flagship) Android builds: a file reference to a mapping
                file used when uploading an APK to the Play store when the
                binary size needs to be reduced. This file's extension must be
                '.txt'.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>app_bundle</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                For (Flagship) Android builds: a file reference to an Android
                App Bundle. This file's extension must be '.aab'.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>espresso</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                For (Flagship) Android builds: a file reference to an Espresso
                APK. This file's extension must be '.apk'
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>manifest_merger_debug</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                For (Flagship) Android builds: a file reference to the debug
                manifest merger report file. This file's extension must be
                '.txt'.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
            <Definition>
              <HighlightedText>manifest_merger_release</HighlightedText>:
              <InfoText type="definition">
                <HighlightedText noFloat weight="less">
                  (optional)
                </HighlightedText>{' '}
                For (Flagship) Android builds: a file reference to the release
                manifest merger report file. This file's extension must be
                '.txt'.
              </InfoText>
            </Definition>
            <ClearFloat />
            <br />
          </CodeSnippet>
        </Body>
        <br />
        <StepTitle>Example cURL Request</StepTitle>
        <Body>
          <CodeSnippet>
            <InfoText spaced>
              curl \
              <br />
              -F "ipa=@/Users/z013sbd/dev/MyApp/build/MyApp.ipa" \
              <br />
              -F "changelog=@/Users/z013sbd/dev/MyApp/commits.txt" \
              <br />
              -F "notes=Test build with home screen scrolling sideways" \
              <br />
              -F "tags=crazy-builds,pr-2354" \
              <br />
              -F "expire_in=3" \
              <br />
              -F "author=Joseph Joestar" \
              <br />
              https://api-internal.target.com/mobile_test_builds/v1/flagship/ios/35aff2dd?key=1234
            </InfoText>
          </CodeSnippet>
        </Body>
      </SubContainer>
    </DocumentationContainer>
  )
}

export default Documentation
