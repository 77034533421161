import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withAuth } from '@praxis/component-auth'

// Styling
import {
  Bar,
  HeaderButton,
  Buttons,
  HomeIcon,
  Text,
  RightButtons,
} from './style'

// Actions
import { isLoggedIn } from '../../actions/authAction'

export const Header = (props) => {
  const { logout, isAuthorized, session } = props.auth
  const { authorizedGroup } = props

  const isAdmin = () => {
    var lowercaseMemberOf = session.userInfo.memberOf.map(function (v) {
      return v.toLowerCase()
    })

    return lowercaseMemberOf.includes(authorizedGroup.toLowerCase())
  }

  return (
    <Bar>
      <HeaderButton disableRipple margin="20px" component={Link} to="/">
        <HomeIcon>
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </HomeIcon>
      </HeaderButton>

      {isAuthorized() && (
        <Buttons>
          <HeaderButton disableRipple component={Link} to="/documentation">
            <Text>Documentation</Text>
          </HeaderButton>

          <HeaderButton
            disableRipple
            margin="15px"
            component={Link}
            to="/register-device"
          >
            <Text>Register Device</Text>
          </HeaderButton>

          <HeaderButton disableRipple margin="15px" component={Link} to="/new">
            <Text important>What's New?</Text>
          </HeaderButton>

          <RightButtons>
            {isAdmin() && (
              <HeaderButton
                disableRipple
                margin="15px"
                component={Link}
                to="/admin"
              >
                <Text>Admin</Text>
              </HeaderButton>
            )}

            <HeaderButton
              button="logout"
              margin="15px"
              disableRipple
              onClick={() => {
                logout()
                props.isLoggedIn(false)
              }}
            >
              <Text>Logout</Text>
            </HeaderButton>
          </RightButtons>
        </Buttons>
      )}
    </Bar>
  )
}

export default connect(null, { isLoggedIn: isLoggedIn })(withAuth()(Header))
