import styled from 'styled-components'

export const NotAuthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  margin-top: ${(props) => props.marginTop && props.marginTop};
`
export const Title = styled.h1`
  margin-bottom: 0px;
`

export const Text = styled.p``
