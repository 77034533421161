import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0px;
    font-family: Helvetica, Arial, sans-serif;
  }
  body.fontLoaded {
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h1, h2, h3, h4 {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};
    font-weight:  normal;
    color: ${(props) => props.theme.colors.black};

  }

  p {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};
    font-weight: 300;
    color: ${(props) => props.theme.colors.darkGray};
  }

`
export default GlobalStyle
