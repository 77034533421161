import styled from 'styled-components'

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

export const HeadingContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`

export const AppContainer = styled.div`
  max-height: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1 100%;
`

export const ButtonContainer = styled.div`
  max-height: 50%;
  display: flex;
  justify-content: center;
`

export const Title = styled.h1`
  font-size: 4em;
  font-weight: 200;
  margin-bottom: 0px;
`

export const Description = styled.p``
