import { Icon, StyledTooltip } from './style'

const StarIcon = ({ margin }) => {
  return (
    <StyledTooltip title="I'm an RC build!">
      <div>
        <Icon margin={margin} viewBox="0 0 22 22">
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </Icon>
      </div>
    </StyledTooltip>
  )
}

export default StarIcon
