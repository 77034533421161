import React from 'react'
import { withEnv } from '@praxis/component-runtime-env'

import {
  WhatsNewContainer,
  Subtitle,
  InfoText,
  HighlightedText,
  Image,
  CenterColumnDiv,
  CenterRowDiv,
  ButtonDiv,
} from './style'
import { HeaderContainer, Title, BodyContainer } from '../RegisterDevice/style'

import InstallButton from '../../components/shared/Install/Button/Button'
import { Divider } from '@material-ui/core'

export class WhatsNew extends React.Component {
  render() {
    const { env } = this.props
    return (
      <WhatsNewContainer>
        <HeaderContainer>
          <Title>What's New?</Title>
          <br />
          <Divider variant="fullWidth" />
        </HeaderContainer>
        <br />
        <BodyContainer>
          <CenterColumnDiv>
            <Image src="/bullflight-logo.png" />
            <br />
            <InfoText>
              <HighlightedText noFloat>BullFlight for iOS</HighlightedText> is
              now available!
            </InfoText>
          </CenterColumnDiv>
          <Subtitle>What is it?</Subtitle>
          <InfoText type="definition">
            It is a native iOS app and similar to the website, allows you to
            view and install any iOS build directly from the app. Instead of
            having to open the website on your device, you can simply access the
            builds directly from the app and the builds are installed the same
            way as on the website.
          </InfoText>
          <br />
          <CenterRowDiv>
            <Image src="/bullflight-ios-demo-flow.gif" larger />
          </CenterRowDiv>
          <br />
          <Subtitle>To install:</Subtitle>
          <InfoText type="definition">
            Open this page on your device, click the install button below, and
            the app will install like any other build!
          </InfoText>
          <ButtonDiv>
            <InstallButton
              display="container"
              url={`itms-services://?action=download-manifest&url=${env.api}/${env.bullflightManifest}/manifest.plist`}
            >
              Install
            </InstallButton>
          </ButtonDiv>
        </BodyContainer>
      </WhatsNewContainer>
    )
  }
}

export default withEnv()(WhatsNew)
