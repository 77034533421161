import { FooterContainer, Text } from './style'
import { Divider } from '@material-ui/core'

const Footer = () => {
  return (
    <FooterContainer>
      <Divider variant="fullWidth" />
      <Text>
        This is a beta version and is not meant to be shared with the public.
      </Text>
    </FooterContainer>
  )
}

export default Footer
