import * as types from '../actions/actionTypes'

const initialState = {
  message: '',
  postDevicePending: false,
  postDeviceSuccessful: false,
  postDeviceError: {
    status: '',
    message: '',
  },
}

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    // POST device
    case types.POST_DEVICE_DATA:
      return Object.assign({}, state, {
        message: action.data.message,
      })
    case types.POST_DEVICE_PENDING:
      return Object.assign({}, state, {
        postDevicePending: action.isPending,
      })
    case types.POST_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        postDeviceSuccessful: action.isSuccessful,
      })
    case types.POST_DEVICE_ERROR:
      return Object.assign({}, state, {
        postDeviceError: action.error,
      })

    default:
      return state
  }
}

// Selectors
export const postDeviceMessage = (state) => state.device.message
export const isPostDevicePending = (state) => state.device.postDevicePending
export const isPostDeviceSuccessful = (state) =>
  state.device.postDeviceSuccessful
export const postDeviceError = (state) => state.device.postDeviceError
