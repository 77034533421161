import * as types from '../actions/actionTypes'

const initialState = {
  builds: {},
  getBuildsPending: false,
  getBuildsSuccessful: false,
  getBuildsErrors: {
    status: '',
    message: '',
  },
  build: {},
  getBuildPending: false,
  getBuildSuccessful: false,
  getBuildErrors: {
    status: '',
    message: '',
  },
}

export default function buildReducer(state = initialState, action) {
  switch (action.type) {
    // GET builds list
    case types.GET_BUILDS_DATA:
      return Object.assign({}, state, {
        builds: action.builds,
      })
    case types.GET_BUILDS_PENDING:
      return Object.assign({}, state, {
        getBuildsPending: action.isPending,
      })
    case types.GET_BUILDS_SUCCESS:
      return Object.assign({}, state, {
        getBuildsSuccessful: action.isSuccessful,
      })
    case types.GET_BUILDS_ERROR:
      return Object.assign({}, state, {
        getBuildsErrors: {
          status: action.errors.status,
          message: action.errors.message,
        },
      })

    // GET build
    case types.GET_BUILD_DATA:
      return Object.assign({}, state, {
        build: action.build,
      })
    case types.GET_BUILD_PENDING:
      return Object.assign({}, state, {
        getBuildPending: action.isPending,
      })
    case types.GET_BUILD_SUCCESS:
      return Object.assign({}, state, {
        getBuildSuccessful: action.isSuccessful,
      })
    case types.GET_BUILD_ERROR:
      return Object.assign({}, state, {
        getBuildErrors: action.error,
      })

    default:
      return state
  }
}

// Selectors
export const getTags = (state) =>
  state.builds.builds && state.builds.builds.tags
export const getVersions = (state) =>
  state.builds.builds && state.builds.builds.versions
export const getAppName = (state) =>
  state.builds.builds && state.builds.builds.name
export const getBuildsSuccessful = (state) => state.builds.getBuildsSuccessful
export const getBuildsPending = (state) => state.builds.getBuildsPending
export const getBuildsErrors = (state) => state.builds.getBuildsErrors
export const selectedBuild = (state) => state.builds.build
export const getBuildPending = (state) => state.builds.getBuildPending
export const getBuildErrors = (state) => state.builds.getBuildErrors
