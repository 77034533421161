import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  width: 250px;
  height: 250px;
`

export const Title = styled.h1`
  margin-bottom: 0px;
`

export const Spinner = styled(CircularProgress)`
  margin: 30px;
`
