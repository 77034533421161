import styled from 'styled-components'

export const InstallContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
`

export const InstallOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid ${(props) => props.theme.colors.gainsboro};
`

export const ContentTitle = styled.h3`
  margin-bottom: 5px;
`

export const InfoText = styled.p`
  margin-top: ${(props) => (props.margin === 'top' ? '20px' : '0px')};
  margin-left: 10px;
  margin-bottom: ${(props) => (props.margin === 'bottom' ? '20px' : '0px')};
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGray};
`
