import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'typeface-roboto'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers/reducers'

import { ThemeProvider } from 'styled-components'
import { primaryTheme } from './theme/theme'

const store = createStore(
  reducers,
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
  ),
)

const root = createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={primaryTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
