import {
  InstallContainer,
  InstallOptionsContainer,
  QRCodeContainer,
  ContentTitle,
  InfoText,
} from './style'
import QRCode from 'qrcode.react'
import InstallButton from '../Button/Button'

const InstallContent = ({ buildURL, qrCodeSize, display }) => {
  return (
    <InstallContainer>
      <ContentTitle>To install:</ContentTitle>
      <InstallOptionsContainer>
        <InfoText margin="bottom">
          Scan the QR code to open this page on your mobile device, then click
          the install button.
        </InfoText>
        <QRCodeContainer>
          <QRCode value={buildURL} renderAs="svg" size={qrCodeSize} />
        </QRCodeContainer>

        {display === 'container' && (
          <InstallButton display="container" url={buildURL} />
        )}
        <InfoText margin="top">
          Once the install button is clicked, view the app's installation
          progress on the home screen (iOS) or in the Files app (Android). If
          you have trouble installing, message the #bullflight channel.
        </InfoText>
      </InstallOptionsContainer>
    </InstallContainer>
  )
}

export default InstallContent
