import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const DocumentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  margin-top: 70px;
`
export const SubContainer = styled.div`
  margin-bottom: 40px;
`

export const Title = styled.h1``

export const Subtitle = styled.h2`
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 500;
`
export const HeadingTitle = styled.h3``

export const StepTitle = styled.h4`
  margin: ${(props) => props.type === 'important' && '0px'};
  margin-left: ${(props) => (props.noLeftMargin ? '0px' : '20px')};
  margin-bottom: ${(props) => props.type === 'important' && '10px'};
  font-weight: ${(props) => props.type === 'important' && '600'};
`

export const Body = styled.div`
  margin-left: ${(props) => !props.noIndent && '40px'};
`

export const InfoText = styled.p`
  float: ${(props) => props.type === 'definition' && 'right'};
  width: ${(props) => props.type === 'definition' && '70%'};
  margin-top: 0px;
  margin-left: ${(props) => props.type === 'definition' && '20px'};
  margin-bottom: ${(props) => (props.type === 'important' ? '20px' : '0px')};
  font-size: 16px;
  font-weight: ${(props) => (props.type === 'important' ? '600' : '300')};
  line-height: ${(props) => (props.spaced ? '20pt' : '15pt')};
  white-space: pre-line;
`

export const CodeSnippet = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 80%;
  word-wrap: break-word;
  height: 100%;
  background-color: ${(props) => props.theme.colors.lighterGray};
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.blue};
  font-weight: 600;
  &:hover {
    color: ${(props) => props.theme.colors.darkBlue};
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
export const ExternalLink = styled.a`
  color: ${(props) => props.theme.colors.blue};
  font-weight: 600;
  &:hover {
    color: ${(props) => props.theme.colors.darkBlue};
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const HighlightedText = styled.b`
  float: ${(props) => !props.noFloat && 'left'};
  color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) => (props.weight === 'less' ? '300' : '500')};
`
export const Definition = styled.div``

export const ClearFloat = styled.div`
  clear: both;
`

export const Note = styled.div`
  margin: 0 auto;
  padding: 30px;
  width: 70%;
  background-color: ${(props) => props.theme.colors.lighterGray};
  border: ${(props) => props.theme.colors.mediumGray};
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
`
