import React from 'react'
import { useNavigate } from 'react-router-dom'
import { withAuth } from '@praxis/component-auth'

// Redux
import { connect } from 'react-redux'

// Actions
import { getApps } from '../../actions/appAction'
import { isLoggedIn } from '../../actions/authAction'

// Reducers/Selectors
import { getAppsList, getAppsPending } from '../../reducers/appReducer'
import { getLoggedIn } from '../../reducers/authReducer'

// Components
import AppCard from '../../components/AppCard/AppCard'

// Styling
import {
  HomeContainer,
  HeadingContainer,
  AppContainer,
  Title,
  Description,
  ButtonContainer,
} from './style'
import PrimaryButton from '../../components/shared/PrimaryButton/PrimaryButton'

export class Home extends React.Component {
  componentDidMount() {
    if (this.props.auth.isAuthorized() && !this.props.loggedIn) {
      this.props.isLoggedIn(true)
      this.props.getApps()
    } else if (!this.props.auth.isAuthorized() && this.props.loggedIn) {
      this.props.isLoggedIn(false)
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthorized() && !this.props.loggedIn) {
      this.props.isLoggedIn(true)
      this.props.getApps()
    }
  }

  handleChangePlatform = (app, platform) => {
    this.props.history(`/build/${app}/${platform}`)
  }

  render() {
    const { login, isAuthorized } = this.props.auth
    const { appsList, isAppsPending } = this.props

    return (
      <HomeContainer>
        <HeadingContainer>
          <Title>BullFlight</Title>
          <Description>
            A place to post, browse, and install iOS and Android test builds.
          </Description>
        </HeadingContainer>
        {isAuthorized() ? (
          <AppContainer>
            {!isAppsPending &&
              appsList.length > 0 &&
              appsList.map((app) => {
                return (
                  <AppCard
                    key={app.name}
                    app={app}
                    onChangePlatform={this.handleChangePlatform}
                    type="appcard"
                  />
                )
              })}
          </AppContainer>
        ) : (
          <ButtonContainer>
            <PrimaryButton kind="login" onClick={login} text="Login" />
          </ButtonContainer>
        )}
      </HomeContainer>
    )
  }
}

function withUseNavigate(Component) {
  return function WrappedComponent(props) {
    const history = useNavigate()
    return <Component {...props} history={history} />
  }
}

export default connect(
  (state) => ({
    appsList: getAppsList(state),
    isAppsPending: getAppsPending(state),
    loggedIn: getLoggedIn(state),
  }),
  {
    getApps: getApps,
    isLoggedIn: isLoggedIn,
  },
)(withAuth()(withUseNavigate(Home)))
