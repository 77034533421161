import { Route, Routes, useParams } from 'react-router-dom'
import { ProtectedElement, withAuth } from '@praxis/component-auth'

// Containers
import Home from '../Home/Home'
import Builds from '../Builds/Builds'
import Install from '../Install/Install'
import Documentation from '../Documentation/Documentation'
import RegisterDevice from '../RegisterDevice/RegisterDevice'
import Admin from '../Admin/Admin'
import WhatsNew from '../WhatsNew/WhatsNew'
import NotFound from '../../components/NotFound/NotFound'
import NotAuthorized from '../../components/NotAuthorized/NotAuthorized'

// Components
import GlobalStyle from '../../theme/globalStyle'
import Header from '../../components/Header/Header'
import { LayoutContainer, ContentContainer } from './style'

const AppPlatform = (props) => {
  let { app, platform } = useParams()

  return <Builds app={app} platform={platform} {...props} />
}

const AppPlatformSha = (props) => {
  let { app, platform, sha } = useParams()

  return <Install app={app} platform={platform} sha={sha} {...props} />
}

function Layout(props) {
  const { authorizedGroup } = props

  return (
    <LayoutContainer>
      <Header authorizedGroup={authorizedGroup} />
      <ContentContainer>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Home {...props} />} />

          <Route
            // react-router-dom v6 doesn't support regex in path
            // path="/build/:app([a-z]+)/:platform(ios|android)"
            path="/build/:app/:platform"
            element={
              <ProtectedElement
                unauthenticatedRoute="/"
                render={(props) => <AppPlatform {...props} />}
              />
            }
          />

          <Route
            path="/documentation"
            element={
              <ProtectedElement
                unauthenticatedRoute="/"
                render={(props) => <Documentation {...props} />}
              />
            }
          />

          <Route
            // react-router-dom v6 doesn't support regex in path
            // path="/build/:app([a-z]+)/:platform(ios|android)/:sha"
            path="/build/:app/:platform/:sha"
            element={
              <ProtectedElement
                unauthenticatedRoute="/"
                render={(props) => <AppPlatformSha {...props} />}
              />
            }
          />

          <Route
            path="/register-device"
            element={
              <ProtectedElement
                unauthenticatedRoute="/"
                render={(props) => <RegisterDevice {...props} />}
              />
            }
          />

          <Route
            path="/new"
            element={
              <ProtectedElement
                unauthenticatedRoute="/"
                render={(props) => <WhatsNew {...props} />}
              />
            }
          />

          <Route
            path="/admin"
            element={
              <ProtectedElement
                allowed={[authorizedGroup]}
                unauthenticatedRoute="/"
                unauthorizedRoute="/not-authorized"
                render={(props) => <Admin {...props} />}
              />
            }
          />

          <Route
            path="/not-authorized"
            element={<NotAuthorized {...props} />}
          />

          <Route element={<NotFound />} />
        </Routes>
      </ContentContainer>
    </LayoutContainer>
  )
}

export default withAuth()(Layout)
