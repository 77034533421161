import * as types from './actionTypes'
import axios from 'axios'
import appConfig from '../config/appConfig'

export function registerDevice(name, udid) {
  return (dispatch) => {
    dispatch(postDevicePending(true))
    return appConfig().then((config) => {
      return axios({
        method: 'post',
        url: `${config.api}/devices?key=${config.key}`,
        data: { name: name, udid: udid },
      })
        .then((response) => {
          dispatch(postDeviceData(response.data))
          dispatch(postDeviceSuccess(true))
          dispatch(postDevicePending(false))
          dispatch(postDeviceError({ status: '', message: '' }))
        })
        .catch((err) => {
          dispatch(postDeviceSuccess(false))
          dispatch(postDevicePending(false))
          dispatch(
            postDeviceError({
              status:
                err.response && err.response.status ? err.response.status : 500,
              message:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Internal Server Error',
            }),
          )
        })
    })
  }
}

function postDevicePending(isPending) {
  return {
    type: types.POST_DEVICE_PENDING,
    isPending,
  }
}

function postDeviceData(data) {
  return {
    type: types.POST_DEVICE_DATA,
    data,
  }
}

function postDeviceSuccess(isSuccessful) {
  return {
    type: types.POST_DEVICE_SUCCESS,
    isSuccessful,
  }
}

function postDeviceError(error) {
  return {
    type: types.POST_DEVICE_ERROR,
    error,
  }
}

export function clearForm() {
  return (dispatch) => {
    dispatch(postDeviceData({ message: '' }))
    dispatch(postDeviceSuccess(false))
    dispatch(postDevicePending(false))
    dispatch(postDeviceError({ status: '', message: '' }))
  }
}
