import styled from 'styled-components'

import { Button, SvgIcon } from '@material-ui/core'

export const BuildsContainer = styled.div`
  margin-top: 70px;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const HeadingContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled.h1``

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const FiltersTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const FiltersTitle = styled.h3`
  margin: 0px;
`

export const FilterIcon = styled(SvgIcon)`
  color: ${(props) => props.theme.colors.black};
  margin-right: 5px;
`

export const FiltersDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ClearFilterContainer = styled.div`
  margin-left: 10px;
`

export const ClearFiltersButton = styled(Button)`
  && {
    color: ${(props) => props.theme.colors.blue};
    padding: 0px;
    text-transform: none;
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};

    & .MuiButton-label {
      &: hover {
        color: ${(props) => props.theme.colors.darkBlue};
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
`

export const BuildsTableContainer = styled.div`
  width: 80%;
`
