import * as types from '../actions/actionTypes'

const initialState = {
  appsList: [],
  isGetAppsPending: false,
  isGetAppsSuccessful: false,
  getAppsErrors: {
    status: '',
    message: '',
  },
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_APPS_DATA:
      return Object.assign({}, state, {
        appsList: action.apps.app_list,
      })

    case types.GET_APPS_PENDING:
      return Object.assign({}, state, {
        isGetAppsPending: action.isPending,
      })

    case types.GET_APPS_SUCCESS:
      return Object.assign({}, state, {
        isGetAppsSuccessful: action.isSuccessful,
      })

    case types.GET_APPS_ERROR:
      return Object.assign({}, state, {
        getAppsErrors: {
          status: action.status,
          message: action.message,
        },
      })

    default:
      return state
  }
}

// Selectors
export const getAppsList = (state) => state.apps.appsList
export const getAppsPending = (state) => state.apps.isGetAppsPending
