import styled from 'styled-components'

import { Table, TableCell, TableRow } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const TableContainer = styled(Table)`
  overflow: auto;
`

export const Header = styled(TableCell)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};
    margin: 0px;
    background-color: ${(props) => props.theme.colors.lighterGray};
    font-size: 15px;
    font-weight: normal;
    height: 20px;
  }
`
export const StyledRow = styled(TableRow)`
  white-space: pre-wrap;
`

export const CellBody = styled(TableCell)`
  && {
    font-family: ${[
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')};
    font-weight: 300;
  }
`

export const InstallLink = styled(Link)`
  font-family: ${[
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',')};
  text-decoration: none;
  color: ${(props) => props.theme.colors.blue};
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.colors.darkBlue};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const BuildDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
