import * as types from '../actions/actionTypes'

const initialState = {
  message: '',
  postProfilesPending: false,
  postProfilesSuccessful: false,
  postProfilesError: {
    status: '',
    message: '',
  },
}

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // POST profile
    case types.POST_PROFILES_DATA:
      return Object.assign({}, state, {
        message: action.data.message,
      })
    case types.POST_PROFILES_PENDING:
      return Object.assign({}, state, {
        postProfilesPending: action.isPending,
      })
    case types.POST_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        postProfilesSuccessful: action.isSuccessful,
      })
    case types.POST_PROFILES_ERROR:
      return Object.assign({}, state, {
        postProfilesError: action.error,
      })
    default:
      return state
  }
}

// Selectors
export const postProfilesMessage = (state) => state.profiles.message
export const isPostProfilesPending = (state) =>
  state.profiles.postProfilesPending
export const isPostProfilesSuccessful = (state) =>
  state.profiles.postProfilesSuccessful
export const postProfilesError = (state) => state.profiles.postProfilesError
