import styled from 'styled-components'

export const BuildContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
`

export const ContentTitle = styled.h3`
  margin-bottom: 0px;
`

export const InfoText = styled.p`
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGray};
`
export const Link = styled.a`
  color: ${(props) => props.theme.colors.blue};
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: ${(props) => props.theme.colors.darkBlue};
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const SubTextDiv = styled.div`
  margin-left: 10px;
`
