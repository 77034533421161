import he from 'he'
import moment from 'moment-timezone'

import { decodeQuotes } from '../../../../services/utils'
import {
  BuildContainer,
  ContentTitle,
  SubTextDiv,
  InfoText,
  Link,
} from './style'

const BuildContent = ({ platform, build }) => {
  const name =
    platform === 'ios'
      ? build.build
      : build.build
        ? `${build.name} ${build.build}`
        : build.name

  const artifacts = [
    'mapping',
    'app_bundle',
    'espresso',
    'manifest_merger_debug',
    'manifest_merger_release',
  ]
  return (
    <BuildContainer>
      <ContentTitle>Build</ContentTitle>
      <SubTextDiv>
        <InfoText>{name}</InfoText>
      </SubTextDiv>
      {build.author && (
        <div>
          <ContentTitle>Author</ContentTitle>
          <SubTextDiv>
            <InfoText>{decodeQuotes(he.encode(build.author))}</InfoText>
          </SubTextDiv>
        </div>
      )}
      {build.notes && (
        <div>
          <ContentTitle>Notes</ContentTitle>
          <SubTextDiv>
            <InfoText>{decodeQuotes(he.encode(build.notes))}</InfoText>
          </SubTextDiv>
        </div>
      )}
      {build.date && (
        <div>
          <ContentTitle>Date Uploaded</ContentTitle>
          <SubTextDiv>
            <InfoText>
              {moment
                .tz(build.date, moment.tz.guess())
                .format('MMM Do YYYY, h:mm a')}
            </InfoText>
          </SubTextDiv>
        </div>
      )}
      {build.changelog && (
        <div>
          <ContentTitle>Changelog</ContentTitle>
          <SubTextDiv>
            <InfoText>{decodeQuotes(he.encode(build.changelog))}</InfoText>
          </SubTextDiv>
        </div>
      )}
      {(build.mapping ||
        build.app_bundle ||
        build.espresso ||
        build.manifest_merger_debug ||
        build.manifest_merger_release) && (
        <div>
          <ContentTitle>Additional Artifacts</ContentTitle>
          {artifacts.map((artifact, i) => {
            return (
              !!build[`${artifact}`] && (
                <SubTextDiv key={i}>
                  <InfoText>
                    {artifact === 'app_bundle'
                      ? 'App Bundle: '
                      : artifact === 'manifest_merger_debug'
                        ? 'Manifest Merger Report (debug): '
                        : artifact === 'manifest_merger_release'
                          ? 'Manifest Merger Report (release): '
                          : artifact.charAt(0).toUpperCase() +
                            artifact.slice(1) +
                            ' file: '}
                  </InfoText>
                  <Link href={build[`${artifact}`]}>
                    {build[`${artifact}`].match(/([^/]+?$)/g)}
                  </Link>
                </SubTextDiv>
              )
            )
          })}
        </div>
      )}
    </BuildContainer>
  )
}

export default BuildContent
