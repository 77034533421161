import React from 'react'
import { connect } from 'react-redux'
// import { withRouter } from 'react-router-dom'
import { withEnv } from '@praxis/component-runtime-env'

// Actions
import { getBuild } from '../../actions/buildAction'

// Reducers/Selectors
import {
  selectedBuild,
  getBuildPending,
  getBuildErrors,
} from '../../reducers/buildReducer'

// Components
import {
  InstallContainer,
  InstallInfoContainer,
  HeadingContainer,
  TitleContainer,
  Title,
  ContentContainer,
  Container,
} from './style'
import {
  BuildContent,
  InstallContent,
  Footer,
  Loading,
} from '../../components/shared/Install'
import { Divider } from '@material-ui/core'
import NotFound from '../../components/NotFound/NotFound'
import StarIcon from '../../components/shared/StarIcon/StarIcon'

import { isRC } from '../../services/utils'

export class Install extends React.Component {
  componentDidMount() {
    this.props.getBuild(this.props.app, this.props.platform, this.props.sha)
  }

  render() {
    const { build, isBuildPending, buildErrors } = this.props
    return (
      <InstallContainer>
        {isBuildPending ? (
          <Loading />
        ) : (
          <Container>
            {buildErrors.status === 404 || buildErrors.status === 422 ? (
              <NotFound text={buildErrors.message} />
            ) : (
              <InstallInfoContainer>
                <HeadingContainer>
                  <TitleContainer>
                    <Title>Install</Title>
                    {isRC(build.tags) && <StarIcon />}
                  </TitleContainer>
                  <Divider variant="fullWidth" />
                </HeadingContainer>
                {!!build && !isBuildPending && (
                  <ContentContainer>
                    <BuildContent
                      platform={this.props.platform}
                      build={build}
                    />
                    <InstallContent
                      // QR component will crash if applied with undefined, which build is on initial render.
                      buildURL={build.url || ''}
                      qrCodeSize={200}
                      display="container"
                    />
                  </ContentContainer>
                )}
                <Footer />
              </InstallInfoContainer>
            )}
          </Container>
        )}
      </InstallContainer>
    )
  }
}

export default connect(
  (state) => ({
    build: selectedBuild(state),
    isBuildPending: getBuildPending(state),
    buildErrors: getBuildErrors(state),
  }),
  {
    getBuild: getBuild,
  },
)(withEnv()(Install))
