import styled from 'styled-components'

export const WhatsNewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
`
export const Subtitle = styled.h3`
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
`
export const CenterColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InfoText = styled.p`
  margin-top: 0px;
  margin-left: ${(props) => props.type === 'definition' && '30px'};
  margin-bottom: ${(props) => (props.type === 'important' ? '20px' : '0px')};
  font-size: 16px;
  font-weight: ${(props) => (props.type === 'important' ? '600' : '300')};
  line-height: 20pt;
  white-space: pre-line;
`

export const HighlightedText = styled.b`
  float: ${(props) => !props.noFloat && 'left'};
  color: ${(props) => props.theme.colors.blue};
  font-weight: 500;
`

export const Image = styled.img`
  width: ${(props) => (props.larger ? '40%' : '20%')};
  height: ${(props) => (props.larger ? '40%' : '20%')};
`

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CenterRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
