import { StyledButton, InstallIcon } from './style'

const Button = ({ display, url }) => {
  return (
    <StyledButton display={display} href={url}>
      Install
      <InstallIcon>
        <path d="M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z" />
      </InstallIcon>
    </StyledButton>
  )
}

export default Button
