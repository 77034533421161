export const primaryTheme = {
  colors: {
    blue: '#037aff',
    royalBlue: '#0064d5',
    darkBlue: '#2d3a85',
    lighterGray: '#f8f9fa',
    lightGray: '#b9bcbe',
    mediumGray: '#adadad',
    darkGray: '#6b757d',
    gainsboro: '#dadde9',
    white: '#ffffff',
    whiteSmoke: '#eeeeee',
    black: '#000000',
    yellow: '#ffd500',
  },

  sizing: {
    install: {
      container: '60%',
      dialog: '90%',
      item: '100%',
    },
  },
}
