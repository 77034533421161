import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export const DialogInstall = styled(Dialog)`
  .MuiPaper-root {
    width: ${(props) => props.size && props.size};
  }
`

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.theme.sizing.install.dialog};
  margin: auto;
  margin-bottom: 10px;
`

export const HeadingContainer = styled.div`
  margin: 0px 20px 0px 20px;
  width: ${(props) => props.theme.sizing.install.item};
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
`

export const Title = styled.h1`
  margin-top: 0px;
  margin-bottom: 0px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${(props) => props.theme.sizing.install.item};
  margin: 20px;
  overflow-y: auto;
`
export const InfoText = styled.p``

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  float: left;
`

export const ButtonDiv = styled.div`
  float: right;
`

export const ClearFloat = styled.div`
  clear: both;
`
