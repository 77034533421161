import { Group, StyledButton } from './style'

const PlatformGroup = ({
  size,
  margin,
  platform,
  app,
  onChangePlatform,
  type,
}) => {
  return (
    <Group
      aria-label="Small outlined button group"
      size={size}
      margin={margin}
      type={type}
    >
      <StyledButton
        type={type}
        disabled={platform === 'ios'}
        onClick={() => onChangePlatform(app, 'ios')}
      >
        iOS
      </StyledButton>
      <StyledButton
        type={type}
        disabled={platform === 'android'}
        onClick={() => onChangePlatform(app, 'android')}
      >
        Android
      </StyledButton>
    </Group>
  )
}

export default PlatformGroup
