import * as types from './actionTypes'
import axios from 'axios'
import appConfig from '../config/appConfig'

export function getBuilds(app, platform) {
  return (dispatch) => {
    dispatch(getBuildsPending(true))
    return appConfig().then((config) => {
      return axios({
        method: 'get',
        url: `${config.api}/${app}/${platform}?key=${config.key}`,
      })
        .then((response) => {
          dispatch(getBuildsData(response.data))
          dispatch(getBuildsSuccessful(true))
          dispatch(getBuildsPending(false))
          dispatch(getBuildsErrors({ status: '', message: '' }))
          return response
        })
        .catch((err) => {
          dispatch(getBuildsSuccessful(false))
          dispatch(getBuildsPending(false))

          // This handles the proxy error...
          dispatch(
            getBuildsErrors({
              status:
                err.response && err.response.data && err.response.status
                  ? err.response.status
                  : 500,
              message:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'Internal Server Error',
            }),
          )
        })
    })
  }
}

function getBuildsPending(isPending) {
  return {
    type: types.GET_BUILDS_PENDING,
    isPending,
  }
}

function getBuildsData(builds) {
  return {
    type: types.GET_BUILDS_DATA,
    builds,
  }
}

function getBuildsSuccessful(isSuccessful) {
  return {
    type: types.GET_BUILDS_SUCCESS,
    isSuccessful,
  }
}

function getBuildsErrors(errors) {
  return {
    type: types.GET_BUILDS_ERROR,
    errors,
  }
}

export function getBuild(app, platform, sha) {
  return (dispatch) => {
    dispatch(getBuildPending(true))
    return appConfig().then((config) => {
      return axios({
        method: 'get',
        url: `${config.api}/${app}/${platform}/${sha}?key=${config.key}`,
      })
        .then((response) => {
          dispatch(getBuildData(response.data))
          dispatch(getBuildSuccessful(true))
          dispatch(getBuildPending(false))
          dispatch(getBuildErrors({ status: '', message: '' }))
        })
        .catch((err) => {
          dispatch(getBuildSuccessful(false))
          dispatch(getBuildPending(false))
          dispatch(
            getBuildErrors({
              status: err.response.status,
              message:
                err.response.status === 422
                  ? 'No build found.'
                  : err.response.data.message,
            }),
          )
        })
    })
  }
}

function getBuildPending(isPending) {
  return {
    type: types.GET_BUILD_PENDING,
    isPending,
  }
}

function getBuildData(build) {
  return {
    type: types.GET_BUILD_DATA,
    build,
  }
}

function getBuildSuccessful(isSuccessful) {
  return {
    type: types.GET_BUILD_SUCCESS,
    isSuccessful,
  }
}

function getBuildErrors(error) {
  return {
    type: types.GET_BUILD_ERROR,
    error,
  }
}

export function clearBuilds() {
  const buildsResponse = {
    builds: [],
    buildsCount: 0,
    iconUrl: '',
    name: '',
    platform: '',
    root: '',
    tags: [],
    versions: [],
  }
  return (dispatch) => {
    dispatch(getBuildsData(buildsResponse))
    dispatch(getBuildsSuccessful(false))
    dispatch(getBuildsPending(false))
    dispatch(getBuildsErrors({ status: '', message: '' }))
  }
}
