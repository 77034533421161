// Apps
export const GET_APPS_DATA = 'GET_APPS_DATA'
export const GET_APPS_PENDING = 'GET_APPS_PENDING'
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS'
export const GET_APPS_ERROR = 'GET_APPS_ERROR'

// Builds
export const GET_BUILDS_PENDING = 'GET_BUILDS_PENDING'
export const GET_BUILDS_DATA = 'GET_BUILDS_DATA'
export const GET_BUILDS_SUCCESS = 'GET_BUILDS_SUCCESS'
export const GET_BUILDS_ERROR = 'GET_BUILDS_ERROR'

export const GET_BUILD_PENDING = 'GET_BUILD_PENDING'
export const GET_BUILD_DATA = 'GET_BUILD_DATA'
export const GET_BUILD_SUCCESS = 'GET_BUILD_SUCCESS'
export const GET_BUILD_ERROR = 'GET_BUILD_ERROR'

export const POST_BUILD_PENDING = 'POST_BUILD_PENDING'
export const POST_BUILD_DATA = 'POST_BUILD_DATA'
export const POST_BUILD_SUCCESS = 'POST_BUILD_SUCCESS'
export const POST_BUILD_ERROR = 'POST_BUILD_ERROR'

// Auth
export const GET_LOGGED_IN = 'GET_LOGGED_IN'

// Device
export const POST_DEVICE_PENDING = 'POST_DEVICE_PENDING'
export const POST_DEVICE_DATA = 'POST_DEVICE_DATA'
export const POST_DEVICE_SUCCESS = 'POST_DEVICE_SUCCESS'
export const POST_DEVICE_ERROR = 'POST_DEVICE_ERROR'

// Admin
export const POST_PROFILES_PENDING = 'POST_PROFILES_PENDING'
export const POST_PROFILES_DATA = 'POST_PROFILES_DATA'
export const POST_PROFILES_SUCCESS = 'POST_PROFILES_SUCCESS'
export const POST_PROFILES_ERROR = 'POST_PROFILES_ERROR'
