import { BrowserRouter as Router } from 'react-router-dom'
import Layout from './containers/Layout/Layout'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import commonConfig from './config/commonConfig'
import { withEnv } from '@praxis/component-runtime-env'

const App = () => {
  return (
    <EnvProvider commonConfig={commonConfig} configPath="/config.json">
      {({ env }) => {
        return (
          <AuthProvider
            authorizationUrl={env.authorizationUrl}
            logoutUrl={env.logoutUrl}
            clientId={env.clientId}
            redirectUri={window.location.origin}
          >
            <Router>
              <Layout authorizedGroup={env.authorizedGroup} />
            </Router>
          </AuthProvider>
        )
      }}
    </EnvProvider>
  )
}
export default withEnv()(App)
