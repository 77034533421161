import * as types from '../actions/actionTypes'

const initialState = {
  loggedIn: false,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOGGED_IN:
      return Object.assign({}, state, {
        loggedIn: action.value,
      })
    default:
      return state
  }
}

// Selectors
export const getLoggedIn = (state) => state.auth.loggedIn
